
/* CSS (Languages.css) */
.languages-container {
  text-align: center;
  padding: 50px;
  background: linear-gradient(135deg, #1e1e2e, #3a0ca3, #4361ee);
  color: white;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1.5s ease-in-out;
}

.title {
  font-size: 3rem;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #f4f4f4, #facc15);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.3rem;
  opacity: 0.9;
  margin-bottom: 30px;
}

.languages-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.language-card {
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 15px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.language-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 25px rgba(255, 255, 255, 0.5);
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.solidity {
  color: #facc15;
}

.java {
  color: #f44336;
}

.golang {
  color: #00acd7;
}

.javascript {
  color: #f7df1e;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
