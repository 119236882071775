/* Contact Section */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 20px;
  background: linear-gradient(135deg, #1e3c72, #2a5298, #6b00b6);
  color: white;
  text-align: center;
}

/* Contact Title */
.contact-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 25px;
  color: #00eaff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 15px rgba(0, 234, 255, 0.8);
}

/* Contact Description */
.contact-description {
  font-size: 1.2rem;
  margin-bottom: 35px;
  max-width: 700px;
  text-align: center;
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.9);
}

/* Contact Form */
.contact-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

/* Form Group */
.form-group {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 12px;
  border-radius: 8px;
  gap: 10px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  color: white;
  font-size: 1rem;
}

/* Form Icons */
.form-icon {
  font-size: 1.5rem;
  color: #ffcc00;
}

/* Submit Button */
.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffcc00;
  color: #1e3c72;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.submit-button:hover {
  background: #ffdd44;
  transform: scale(1.05);
}

.submit-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Responsive */
@media (max-width: 600px) {
  .contact-title {
    font-size: 2.5rem;
  }

  .contact-form {
    max-width: 90%;
  }
}
/* Success Message */
.success-message {
  background: rgba(0, 255, 127, 0.9);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
}

/* Fade-in and fade-out animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}