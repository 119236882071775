.case-studies {
    padding: 90px 20px;
    background: linear-gradient(135deg, #1e3c72, #2a5298, #6b00b6);
    color: white;
    text-align: center;
  }
  
  .case-studies h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #00eaff;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0 0 15px rgba(0, 234, 255, 0.8);
  }
  
  .case-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;
  }
  
  .case-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    width: 300px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    transition: transform 0.3s, background 0.3s;
    position: relative;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .case-icon {
    font-size: 50px;
    display: block;
    margin-bottom: 10px;
  }
  
  .case-card h3 {
    font-size: 1.4rem;
    color: #ffcc00;
  }
  
  .case-card h4 {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .case-description {
    font-size: 1rem;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .case-card.hovered {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }
  
  .case-card.hovered .case-description {
    opacity: 1;
    max-height: 150px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .case-container {
      flex-direction: column;
      align-items: center;
    }
  }