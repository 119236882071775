.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e1e2e, #3a0ca3, #4361ee);
  text-align: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  animation: gradientMove 6s infinite alternate ease-in-out;
  font-family: 'Poppins', sans-serif;
}

/* Gradient Animation */
@keyframes gradientMove {
  0% { background: linear-gradient(135deg, #1e1e2e, #3a0ca3, #4361ee); }
  100% { background: linear-gradient(135deg, #4361ee, #3a0ca3, #1e1e2e); }
}

.hero-content {
  max-width: 800px;
  animation: fadeIn 1.2s ease-in-out;
}

/* Smooth Fade-in Animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Updated Hero Title */
.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4);
  line-height: 1.2;
  background: linear-gradient(45deg, #f4f4f4, #facc15);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 1.5s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}

/* Subtitle */
.hero-subtitle {
  font-size: 1.3rem;
  margin-bottom: 25px;
  opacity: 0.9;
  animation: fadeIn 1.8s ease-in-out;
  font-weight: 500;
}

/* Buttons */
.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.cta-button {
  padding: 14px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 6px 20px rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  color: white;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
}

.cta-button:hover {
  background: rgba(255, 255, 255, 0.4);
  color: black;
  transform: scale(1.05);
  box-shadow: 0px 8px 25px rgba(255, 255, 255, 0.5);
}

/* Outline Button */
.cta-button.outline {
  background: transparent;
  border: 2px solid white;
  color: white;
}

.cta-button.outline:hover {
  background: white;
  color: #3a0ca3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .hero-buttons {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .hero {
    height: auto;
    padding: 50px 20px;
  }

  .hero-title {
    font-size: 2.2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .cta-button {
    padding: 12px 22px;
    font-size: 1rem;
  }
}