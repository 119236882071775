/* NAVBAR */
.navbar {
  display: flex;
  justify-content: center; /* Center all elements */
  align-items: center;
  padding: 10px 50px;
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  height: 70px;
  box-sizing: border-box;
}

/* LOGO */
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-right: auto;
}

/* NAVIGATION LINKS */
.nav-links {
  list-style: none;
  display: flex;
  gap: 30px; /* Keeps space between links */
  margin: 0;
  padding: 0;
}

.nav-links li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  transition: 0.3s;
}

.nav-links li a:hover {
  color: #ff8a00;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
}

/* HIRE ME BUTTON */
.hire-me {
  padding: 10px 22px;
  background: #ff8a00;
  color: white;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(255, 140, 0, 0.4);
  white-space: nowrap;
  margin-left: 40px; /* Shift slightly left for better alignment */
}

.hire-me:hover {
  background: white;
  color: #ff8a00;
}

/* RESPONSIVE DESIGN */
@media (max-width: 1024px) {
  .navbar {
    padding: 10px 30px;
  }

  .nav-links {
    gap: 20px;
  }

  .hire-me {
    margin-left: 20px; /* Adjust left spacing */
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .logo {
    font-size: 1rem;
    padding: 5px 5px;
  }

  .nav-links {
    gap: 15px;
  }

  .hire-me {
    padding: 8px 18px;
    font-size: 0.9rem;
    margin-left: auto; /* Align properly */
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px 15px;
    justify-content: space-between;
  }

  .logo {
    font-size: 0.8rem;
    padding: 5px 5px;
  }

  .nav-links {
    gap: 12px; /* Reduce space on mobile */
  }

  .hire-me {
    padding: 6px 14px;
    font-size: 0.85rem;
    margin-left: 10px; /* Ensures it stays inside */
  }
}